import customProtocolCheck from "custom-protocol-check";
import yaml from "js-yaml";
import type {
  SerializedEditorState,
  SerializedLexicalNode,
  SerializedParagraphNode,
  SerializedTextNode,
} from "lexical";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { type ComponentInternalInstance } from "vue";

import {
  EMPTY_SERIALIZED_EDITOR_STATE,
  SERIALIZED_PARAGRAPH_NODE,
  SERIALIZED_ROOT_NODE,
  SERIALIZED_TEXT_NODE,
} from "~/constants/lexical";
import {
  DartboardKind,
  DashboardKind,
  DocKind,
  EntityName,
  Feature,
  FolderKind,
  GoogleLoginAction,
  GoogleLoginPlatform,
  PageKind,
  SpaceKind,
  ViewKind,
} from "~/shared/enums";
import type {
  Comment,
  Dartboard,
  Dashboard,
  Doc,
  Folder,
  Form,
  GoogleAuthState,
  Page,
  PropertyValue,
  Space,
  SpecificPageKind,
  Task,
  TimeTracking,
  User,
  View,
} from "~/shared/types";

import { DART_URL_INCLUDING_LOCAL_REGEX } from "./validation";

const EDITABLE_INPUT_TYPES = new Set(["text", "email", "number", "password", "search", "tel", "url"]);

const PREFIX_SECRET_API_KEY = "dsa_";
const PREFIX_SECRET_WEBHOOK = "dsw_";
const HEX_CHARS = [..."0123456789abcdef"];
const DUID_CHARS = Array.from(Array(26).keys())
  .map((i) => String.fromCharCode(i + 65))
  .concat(Array.from(Array(26).keys()).map((i) => String.fromCharCode(i + 97)))
  .concat(Array.from(Array(10).keys()).map((i) => `${i}`))
  .sort();

export const TASK_URL_SUFFIX_REGEX =
  /^\/(?:t|[dv]\/[a-zA-Z0-9]{12}|search|my-tasks|trash)\/(?<duid>[a-zA-Z0-9]{12})(?:-[a-zA-Z0-9-]*)?$/;
export const DOC_URL_SUFFIX_REGEX = /^\/(?:o|f\/[a-zA-Z0-9]{12})\/(?<duid>[a-zA-Z0-9]{12})(?:-[a-zA-Z0-9-]*)?$/;

const NOTION_URL_REGEX = /^https:\/\/(www.)?notion.so/;
const UUID_REGEX = /\b([a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[a-f0-9]{12})\b/;

const TITLE_WITH_COPY_REGEX = /(?<base>.*) copy(?: [1-9]\d*)?$/;

// TODO this (and other places that just directly check a combined type) can cause problems because e.g. FolderKind.OTHER === SpaceKind.OTHER === "Other"
const PAGE_KINDS_WITH_OWN_TITLES = new Set<SpecificPageKind>([
  DartboardKind.CUSTOM,
  DashboardKind.DASHBOARD,
  DocKind.DOC,
  FolderKind.DEFAULT,
  FolderKind.OTHER,
  SpaceKind.OTHER,
  SpaceKind.WORKSPACE,
  ViewKind.CUSTOM,
]);
const VOWELS = new Set(["a", "e", "i", "o", "u"]);
const NO_SPECIAL_NUMBERS_TO_TEXT_MAP = new Map([
  [0, "zero"],
  [1, "one"],
]);
const NUMBERS_TO_TEXT_MAP = new Map([
  [0, "no"],
  [1, "a"],
  [2, "two"],
  [3, "three"],
  [4, "four"],
  [5, "five"],
  [6, "six"],
  [7, "seven"],
  [8, "eight"],
  [9, "nine"],
  [10, "ten"],
]);
const NUMBERS_TO_ORDINAL_TEXT_MAP = new Map([
  [0, "zeroth"],
  [1, "first"],
  [2, "second"],
  [3, "third"],
  [4, "fourth"],
  [5, "fifth"],
  [6, "sixth"],
  [7, "seventh"],
  [8, "eighth"],
  [9, "ninth"],
  [10, "tenth"],
]);
const NUMBERS_TO_UNUSUAL_ORDINAL_SUFFIX_MAP = new Map([
  [1, "st"],
  [2, "nd"],
  [3, "rd"],
]);

export const escapeRegex = (string: string) => string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");

export const getWithIndefiniteArticle = (s: string) =>
  `${s.length === 0 || !VOWELS.has(s.charAt(0).toLowerCase()) ? "a" : "an"} ${s}`;

export const getNumberText = (n: number, options: { definite?: boolean; noSpecial?: boolean } = {}) => {
  const { definite = false, noSpecial = false } = options;
  if (definite && n === 1) {
    return "the";
  }
  if (noSpecial && NO_SPECIAL_NUMBERS_TO_TEXT_MAP.has(n)) {
    return NO_SPECIAL_NUMBERS_TO_TEXT_MAP.get(n);
  }
  return NUMBERS_TO_TEXT_MAP.get(n) ?? `${n}`;
};

export const getOrdinalText = (n: number) => {
  if (NUMBERS_TO_ORDINAL_TEXT_MAP.has(n)) {
    return NUMBERS_TO_ORDINAL_TEXT_MAP.get(n);
  }
  const abs = Math.abs(n);
  if (abs >= 11 && abs <= 13) {
    return `${n}th`;
  }
  const lastDigit = abs % 10;
  return `${n}${NUMBERS_TO_UNUSUAL_ORDINAL_SUFFIX_MAP.get(lastDigit) ?? "th"}`;
};

export const getItemCountText = (
  n: number,
  singular: string,
  options: { definite?: boolean; noSpecial?: boolean; unusualPlural?: string } = {}
) => {
  const { definite = false, noSpecial = false, unusualPlural } = options;
  const plural = unusualPlural ?? `${singular}s`;
  return `${getNumberText(n, { definite, noSpecial })} ${n === 1 ? singular : plural}`;
};

export const getNextTitleInSequence = (base: string, existingTitles: string[], options?: { noCopy?: boolean }) => {
  const { noCopy = false } = options ?? {};
  if (!existingTitles.includes(base)) {
    return base;
  }
  let baseNorm = base;
  if (!noCopy) {
    const match = base.match(TITLE_WITH_COPY_REGEX);
    if (match) {
      baseNorm = match.groups!.base;
    }
    baseNorm += " copy";
    if (!existingTitles.includes(baseNorm)) {
      return baseNorm;
    }
  }

  const regex = new RegExp(`^${escapeRegex(baseNorm)} (?<num>[1-9]\\d*)$`);
  const matchingNumbers = new Set(
    existingTitles
      .map((e) => {
        const match = e.match(regex);
        if (!match) {
          return null;
        }
        return parseInt(match.groups!.num, 10);
      })
      .filter((e) => e !== null)
  );
  for (let i = 2; i < matchingNumbers.size + 3; i += 1) {
    if (matchingNumbers.has(i)) {
      continue;
    }
    return `${baseNorm} ${i}`;
  }
  throw new Error("Couldn't find a new title in sequence");
};

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const getHasTouchCapabilities = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  (navigator as unknown as { msMaxTouchPoints: number }).msMaxTouchPoints > 0;

export const randomSample = <T>(arr: T[], k = 1): T[] =>
  Array.from(Array(k), () => arr[Math.floor(Math.random() * arr.length)]);

export const isObject = (objMaybe: unknown): objMaybe is Record<string, unknown> =>
  objMaybe !== null && typeof objMaybe === "object";

export const isNumber = (numMaybe: unknown): numMaybe is number => typeof numMaybe === "number";

export const isString = (strMaybe: unknown): strMaybe is string =>
  typeof strMaybe === "string" || strMaybe instanceof String;

export const getCookieValue = (name: string) => {
  const res = [...document.cookie.matchAll(new RegExp(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`, "g"))];
  if (res.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`Cookie ${name} has more than one value`);
  }
  return res.length !== 0 ? res[0][2] : undefined;
};

export const deleteCookie = (name: string, domain: string | null = null) => {
  const domainSection = domain ? ` domain=${domain};` : "";
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${domainSection}`;
};

export const makeDuid = () =>
  // TODO this probably isn't random enough
  randomSample(DUID_CHARS, 12).join("");

const randomHex = (length: number) =>
  // TODO this probably isn't random enough
  randomSample(HEX_CHARS, length * 2).join("");
export const makeSecretApiKey = () => `${PREFIX_SECRET_API_KEY}${randomHex(32)}`;

export const makeSecretWebhook = () => `${PREFIX_SECRET_WEBHOOK}${randomHex(32)}`;

export const makeUuid = () => {
  if (crypto.randomUUID !== undefined) {
    return crypto.randomUUID();
  }
  return uuidv4();
};

export const getCurrentUrl = () => window.location.href;

export const copyToClipboard = (text: string, html?: string) => {
  // simple case, all browsers
  if (html === undefined) {
    navigator.clipboard.writeText(text);
    return;
  }

  // firefox polyfill
  if (window.ClipboardItem === undefined) {
    const listener = (event: ClipboardEvent) => {
      event.preventDefault();
      if (event.clipboardData) {
        event.clipboardData.setData("text/plain", text);
        event.clipboardData.setData("text/html", html);
      }
      document.removeEventListener("copy", listener);
    };

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    return;
  }

  // standard case
  navigator.clipboard.write([
    new ClipboardItem({
      "text/plain": new Blob([text], { type: "text/plain" }),
      "text/html": new Blob([html], { type: "text/html" }),
    }),
  ]);
};

export const getEntityKindAndDuidFromUrl = (
  url: string
): { kind: EntityName; duid: string } | { kind: EntityName.UNKNOWN; duid: undefined } => {
  const failResponse: { kind: EntityName.UNKNOWN; duid: undefined } = { kind: EntityName.UNKNOWN, duid: undefined };

  const groups = DART_URL_INCLUDING_LOCAL_REGEX.exec(url)?.groups ?? {};
  const suffix = groups.suffix ?? groups.suffixLocal;
  if (!suffix) {
    return failResponse;
  }

  const taskMatch = TASK_URL_SUFFIX_REGEX.exec(suffix);
  if (taskMatch) {
    const taskGroups = taskMatch.groups ?? {};
    return { kind: EntityName.TASK, duid: taskGroups.duid };
  }

  const docMatch = DOC_URL_SUFFIX_REGEX.exec(suffix);
  if (docMatch) {
    const docGroups = docMatch.groups ?? {};
    return { kind: EntityName.DOC, duid: docGroups.duid };
  }

  return failResponse;
};

export const getPageDisplayName = (
  page: Page | undefined,
  getSpaceFn: (spaceDuid: string) => Space | undefined
): string => {
  if (!page) {
    return "";
  }
  if (page.pageKind === PageKind.DARTBOARD && page.kind === DartboardKind.FINISHED) {
    const space = getSpaceFn(page.spaceDuid);
    if (!space) {
      return page.title;
    }
    return space.sprintNameFmt
      .replace("{N}", page.index !== null ? `${page.index}` : "")
      .replace("{S}", page.startedAt ? moment(page.startedAt).format("MMM D") : "")
      .replace("{F}", page.finishedAt ? moment(page.finishedAt).format("MMM D") : "");
  }
  if (!PAGE_KINDS_WITH_OWN_TITLES.has(page.kind)) {
    return page.kind;
  }
  return page.title;
};

type TrimOptions = { len: number; maxUnder?: number };

export const trimSlugStr = (str: string, options: TrimOptions): string => {
  const { len } = options;
  const { maxUnder = Math.floor(len / 6) } = options;
  if (str.length <= len) {
    return str;
  }
  for (let i = 1; i <= maxUnder; i += 1) {
    if (str[len - i] === "-") {
      return str.substring(0, len - i);
    }
  }
  return str.substring(0, len);
};

export const slugifyStr = (str: string, options: { lower?: boolean; trim?: TrimOptions } = {}): string => {
  const { lower = false, trim } = options;
  const lowered = lower ? str.toLowerCase() : str;
  const formatted = lowered
    .replaceAll("'", "")
    .replaceAll(/[^a-zA-Z0-9-]+/g, "-")
    .replaceAll(/-{2,}/g, "-")
    .replaceAll(/^-|-$/g, "");
  return trim ? trimSlugStr(formatted, trim) : formatted;
};

// TODO there are sorta three copies of these: in EnvironmentStore; in router/common; and in utils/common
export const getReportsLink = (space: Space) => ({
  name: "reports",
  params: {
    spaceDuid: space.duid,
    slugSep: "-",
    slug: slugifyStr(
      getPageDisplayName(space, () => undefined),
      { trim: { len: 30 } }
    ),
  },
});

export const getDashboardLink = (dashboard: Dashboard) => ({
  name: "dashboard",
  params: {
    dashboardDuid: dashboard.duid,
    slugSep: "-",
    slug: slugifyStr(dashboard.title, { trim: { len: 30 } }),
  },
});

export const getFolderLink = (folder: Folder, getSpaceFn: (spaceDuid: string) => Space | undefined) => {
  if (folder.kind === FolderKind.REPORTS) {
    return getReportsLink(getSpaceFn(folder.spaceDuid) as Space);
  }
  return {
    name: "folder",
    params: {
      folderDuid: folder.duid,
      slugSep: "-",
      slug: slugifyStr(
        getPageDisplayName(folder, () => undefined),
        { trim: { len: 30 } }
      ),
    },
  };
};

export const getDartboardLink = (dartboard: Dartboard, getSpaceFn: (spaceDuid: string) => Space | undefined) => ({
  name: "dartboard",
  params: {
    dartboardDuid: dartboard.duid,
    slugSep: "-",
    slug: slugifyStr(getPageDisplayName(dartboard, getSpaceFn), { trim: { len: 30 } }),
  },
});

export const getDocLink = (doc: Doc) => ({
  name: "doc",
  params: {
    folderDuid: doc.folderDuid,
    docDuid: doc.duid,
    slugSep: "-",
    slug: slugifyStr(doc.title, { trim: { len: 30 } }),
  },
});

export const getFormLink = (form: Form) => ({ name: "home", query: { settings: "forms", form: form.duid } });

export const getViewLink = (view: View) => {
  switch (view.kind) {
    case ViewKind.SEARCH: {
      return { name: "search" };
    }
    case ViewKind.MY_TASKS: {
      return { name: "my_tasks" };
    }
    case ViewKind.TRASH: {
      return { name: "trash" };
    }
    case ViewKind.CUSTOM: {
      return {
        name: "view",
        params: {
          viewDuid: view.duid,
          slugSep: "-",
          slug: slugifyStr(
            getPageDisplayName(view, () => undefined),
            { trim: { len: 30 } }
          ),
        },
      };
    }
    default: {
      throw new Error(`Unknown view kind: ${view.kind}`);
    }
  }
};

export const getTaskLink = (task: Task) => {
  const taskDuidAndSlug = `${task.duid}-${slugifyStr(task.title, { trim: { len: 30 } })}`;
  if (task.inTrash) {
    return { name: "trash", params: { taskDuidAndSlug } };
  }
  return { name: "dartboard", params: { dartboardDuid: task.dartboardDuid, taskDuidAndSlug } };
};

export const getCommentLink = (task: Task, comment: Comment) => ({ ...getTaskLink(task), query: { c: comment.duid } });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getUserLink = (user: User) => ({ name: "home", query: { settings: "teammates" } });

export const getPageLink = (page: Page, getSpaceFn: (spaceDuid: string) => Space | undefined) => {
  switch (page.pageKind) {
    case PageKind.SPACE: {
      return getReportsLink(page);
    }
    case PageKind.FOLDER: {
      return getFolderLink(page, getSpaceFn);
    }
    case PageKind.HOME: {
      return { name: "home" };
    }
    case PageKind.INBOX: {
      return { name: "inbox" };
    }
    case PageKind.VIEWS_ROOT: {
      return { name: "views" };
    }
    case PageKind.DASHBOARD: {
      return getDashboardLink(page);
    }
    case PageKind.DASHBOARDS_ROOT: {
      return { name: "dashboards" };
    }
    case PageKind.DARTBOARD: {
      return getDartboardLink(page, getSpaceFn);
    }
    case PageKind.DOC: {
      return getDocLink(page);
    }
    case PageKind.FORM: {
      return getFormLink(page);
    }
    case PageKind.VIEW: {
      return getViewLink(page);
    }
    default: {
      throw new Error("Unknown page kind");
    }
  }
};

export const getPublicFormLink = (form: Form) => ({
  name: "public_form",
  params: { formDuid: form.duid, slugSep: "-", slug: slugifyStr(form.title, { trim: { len: 30 } }) },
});

export const getPublicViewLink = (view: View) => {
  const viewLink = getViewLink(view);
  if (!viewLink.params) {
    throw new Error(`Can't make public link for view kind: ${view.kind}`);
  }

  return `${window.location.origin}/p/v/${viewLink.params.viewDuid}${viewLink.params.slugSep}${viewLink.params.slug}`;
};

const extractAndNormalizeUuid = (str: string) => {
  const match = str.match(UUID_REGEX);
  if (!match) {
    return null;
  }
  return match[1].replace("-", "");
};

/** Get Notion page URL
 * @param pageId Notion page ID
 * @returns Notion page URL
 */
export const getNotionPageUrl = (pageId: string) => `https://notion.so/${(pageId || "").replace(/-/g, "")}`;

/** Get Notion page ID from URL
 * @param url Notion page URL
 * @returns Notion page ID
 */
export const getNotionPageId = (url: string): string | null => {
  // Check if URL is a Notion page
  if (!NOTION_URL_REGEX.test(url)) {
    return null;
  }

  // Extract p param
  const parsedUrl = new URL(url);
  const p = parsedUrl.searchParams.get("p");
  if (p) {
    return extractAndNormalizeUuid(p);
  }

  // Return basic otherwise
  return extractAndNormalizeUuid(url.split("?")[0]);
};

export const someInPath = (event: Event, predicate: (elem: HTMLElement) => boolean): boolean =>
  event.composedPath().some((e) => predicate(e as HTMLElement));

export const hasClassInPath = (event: Event, classes: Set<string>): boolean =>
  someInPath(event, (e) => Array.from(e.classList ?? []).some((f) => classes.has(f)));

export const someInHierarchy = (target: HTMLElement | null, predicate: (elem: HTMLElement) => boolean): boolean => {
  let curr = target;
  for (let i = 0; i < 1000; i += 1) {
    if (!curr) {
      break;
    }
    if (predicate(curr)) {
      return true;
    }
    curr = curr.parentElement;
  }
  return false;
};

export const hasClassInHierarchy = (target: HTMLElement | null, classes: Set<string>): boolean =>
  someInHierarchy(target, (e) => Array.from(e.classList ?? []).some((f) => classes.has(f)));

export const makeEmptyLexicalState = (): SerializedEditorState => EMPTY_SERIALIZED_EDITOR_STATE;

export const makeLexicalStateWithText = (text: string): SerializedEditorState => ({
  root: {
    ...SERIALIZED_ROOT_NODE,
    children: [
      {
        ...SERIALIZED_PARAGRAPH_NODE,
        children: [
          {
            ...SERIALIZED_TEXT_NODE,
            text,
          },
        ] as SerializedTextNode[],
      },
    ] as SerializedParagraphNode[],
  },
});

const isSerializedParagraphNode = (node: SerializedLexicalNode): node is SerializedParagraphNode =>
  node.type === "paragraph";

export const isLexicalStateEmpty = (description: SerializedEditorState): boolean => {
  const { root } = description;
  if ((root.children?.length ?? 0) === 0) {
    return false;
  }
  if (root.children.length !== 1) {
    return false;
  }
  const paragraph = root.children[0];
  if (!isSerializedParagraphNode(paragraph)) {
    return false;
  }
  return (paragraph.children?.length ?? 0) === 0;
};

const LEXICAL_SPECIAL_KEYS = new Set(["version", "children", "mode"]);
const LEXICAL_TEXT_MODE_REMAP = ["normal", "token", "segmented"];

const normalizeLexicalTextMode = (mode: string | number | undefined): string | undefined =>
  isString(mode) || mode === undefined ? mode : LEXICAL_TEXT_MODE_REMAP[mode];

const areLexicalStatesSameRecursive = (objA: SerializedLexicalNode, objB: SerializedLexicalNode): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const objAAny = objA as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const objBAny = objB as any;

  if (objAAny === objBAny) {
    return true;
  }

  const keysA = Object.keys(objAAny).filter((e) => !LEXICAL_SPECIAL_KEYS.has(e));
  const keysB = Object.keys(objBAny).filter((e) => !LEXICAL_SPECIAL_KEYS.has(e));
  if (
    keysA.length !== keysB.length ||
    !keysA.every((key) => keysB.includes(key) && objAAny[key] === objBAny[key]) ||
    (objAAny.version ?? 1) !== (objBAny.version ?? 1) ||
    normalizeLexicalTextMode(objAAny.mode) !== normalizeLexicalTextMode(objBAny.mode)
  ) {
    return false;
  }

  if (
    objAAny.children?.length !== objBAny.children?.length ||
    objAAny.children?.some(
      (child: SerializedLexicalNode, i: number) => !areLexicalStatesSameRecursive(child, objBAny.children[i])
    )
  ) {
    return false;
  }

  return true;
};

export const areLexicalStatesSame = (
  descriptionA: SerializedEditorState,
  descriptionB: SerializedEditorState
): boolean => areLexicalStatesSameRecursive(descriptionA.root, descriptionB.root);

type SimplifiedState = {
  type: string;
  value?: string;
  text?: string;
  children?: SimplifiedState[];
};

const simplifyLexicalStateRecursive = (serializedNode: SerializedLexicalNode): SimplifiedState => {
  const { type } = serializedNode;
  let children;
  if ("children" in serializedNode) {
    const childNodes = serializedNode.children as SerializedLexicalNode[];
    if (childNodes.length > 0) {
      children = childNodes.map((e) => simplifyLexicalStateRecursive(e));
    }
  }
  const value = "value" in serializedNode ? (serializedNode.value as string) : undefined;
  const text = value === undefined && "text" in serializedNode ? (serializedNode.text as string) : undefined;
  return { type, value, text, children };
};

export const TESTONLYprettyPrintLexicalState = (serializedState: SerializedEditorState) => {
  // eslint-disable-next-line no-console
  console.log(yaml.dump(simplifyLexicalStateRecursive(serializedState.root)));
};

export const getText = (description: SerializedEditorState): string => {
  const result: string[] = [];
  const stack = [...description.root.children];
  while (stack.length > 0) {
    const node = stack.shift();
    if (!node) {
      break;
    }
    if (node.type === "text") {
      result.push((node as SerializedTextNode).text);
    }
    if ("children" in node) {
      stack.unshift(...(node.children as SerializedLexicalNode[]));
    }
  }
  return result.join(" ");
};

export const getGoogleUrl = (
  action: GoogleLoginAction,
  redirectUri: string,
  isDesktopApp: boolean,
  isMobileApp: boolean,
  hintEmail: string | undefined,
  referralTokenDuid: string | undefined,
  nextUrl: string | undefined
) => {
  const platform = isDesktopApp
    ? GoogleLoginPlatform.DESKTOP
    : isMobileApp
      ? GoogleLoginPlatform.MOBILE
      : GoogleLoginPlatform.WEB;
  const state: GoogleAuthState = { action, platform, token: referralTokenDuid, next: nextUrl };
  const options: { [key: string]: string } = {
    redirect_uri: redirectUri,
    client_id: "930770543722-9vfoome1e078s98viq1umhtm89j6gdh5.apps.googleusercontent.com", // spell-checker: disable-line
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "openid",
    ].join(" "),
    state: JSON.stringify(state),
  };

  if (hintEmail) {
    options.login_hint = hintEmail;
  }

  const qs = new URLSearchParams(options);
  return `https://accounts.google.com/o/oauth2/v2/auth?${qs.toString()}`;
};

export const openPathInDesktop = (path: string): Promise<boolean> =>
  new Promise((resolve) => {
    customProtocolCheck(
      `dart://${path}`,
      () => resolve(false),
      () => resolve(true)
    );
  });

export const makeDartboardEmail = (dartboard: Dartboard, isProd: boolean) => {
  const id =
    dartboard.kind === DartboardKind.CUSTOM || dartboard.kind === DartboardKind.FINISHED
      ? dartboard.duid
      : `${dartboard.spaceDuid}.${dartboard.kind.toLowerCase()}`;
  return `create.task+${id}@${isProd ? "mail" : "mail-dev"}.itsdart.com`;
};

export const prettyFormatList = (list: string[]): string => {
  if (list.length === 0) {
    return "";
  }
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return `${list[0]} and ${list[1]}`;
  }
  return `${list.slice(0, -1).join(", ")}, and ${list[list.length - 1]}`;
};

export const filterInPlace = <T>(arr: T[], condition: (val: T, index: number, arr: T[]) => boolean) => {
  let newLength = 0;

  for (let i = 0; i < arr.length; i += 1) {
    const val = arr[i];
    if (condition(val, i, arr)) {
      // eslint-disable-next-line no-param-reassign
      arr[newLength] = val;
      newLength += 1;
    }
  }

  // eslint-disable-next-line no-param-reassign
  arr.length = newLength;
  return arr;
};

export const replaceInPlace = <T>(arr: T[], vals: T[]) => {
  // eslint-disable-next-line no-param-reassign
  arr.length = 0;
  arr.push(...vals);
  return arr;
};

export const isTargetEditable = (target: EventTarget) => {
  if (target instanceof HTMLElement && target.isContentEditable) {
    return true;
  }
  if (target instanceof HTMLInputElement) {
    if (EDITABLE_INPUT_TYPES.has(target.type)) {
      return !(target.disabled || target.readOnly);
    }
  }
  if (target instanceof HTMLTextAreaElement) {
    return !(target.disabled || target.readOnly);
  }
  return false;
};

export const intersectionOfAllSets = <T>(sets: Set<T>[]): Set<T> => {
  if (sets.length === 0) {
    return new Set();
  }
  const result = new Set(sets[0]);
  sets.slice(1).forEach((set) => {
    result.forEach((e) => {
      if (!set.has(e)) {
        result.delete(e);
      }
    });
  });
  return result;
};

export const unionOfAllSets = <T>(sets: Set<T>[]): Set<T> => {
  if (sets.length === 0) {
    return new Set();
  }
  const result = new Set(sets[0]);
  sets.slice(1).forEach((set) => {
    set.forEach((e) => result.add(e));
  });
  return result;
};

export const TESTONLYblockThread = (ms: number) => {
  const start = Date.now();
  while (Date.now() - start < ms) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ignore = Math.sqrt(Math.random());
  }
};

export const TESTONLYgetStack = () => {
  const { stack } = new Error();
  if (!stack) {
    return "";
  }
  return stack
    .split("\n")
    .slice(2)
    .map((e) => e.slice(7))
    .join("\n");
};

export const openFilePicker = (onChange: (fileList: File[]) => void) => {
  const inputElement = document.createElement("input");
  inputElement.type = "file";

  inputElement.addEventListener("change", (event) => {
    const target = event.target as HTMLInputElement;
    if (!target.files) {
      return;
    }

    onChange(Array.from(target.files));
  });

  inputElement.click();
};

export const simpleIntHash = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i += 1) {
    const char = text.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
  }
  // eslint-disable-next-line no-bitwise
  return hash >>> 0;
};

export const simpleStrHash = (text: string) => simpleIntHash(text).toString(36);

export const getFeatureOption = (feature: Feature, duid: string, nOptions: number) =>
  simpleIntHash(JSON.stringify([feature, duid])) % nOptions;

export const getComponentAncestors = (component: ComponentInternalInstance | null) => {
  let curr = component;
  const res: string[] = [];
  while (curr) {
    const name = curr.type.name ?? curr.type.__name;
    if (name) {
      res.push(name);
    }
    curr = curr.parent;
  }
  return res;
};

const canvas = document.createElement("canvas");
const context = canvas.getContext("2d");

export const getCharacterPosition = (elem: HTMLElement | null, event: MouseEvent | undefined): number | undefined => {
  if (!event) {
    return undefined;
  }
  if ("caretRangeFromPoint" in document) {
    return document.caretRangeFromPoint(event.clientX, event.clientY)?.startOffset;
  }

  // TODO handle vertical position for firefox and when this is full deprecated

  if (!context || !elem) {
    return undefined;
  }

  context.font = window.getComputedStyle(elem).font;
  const text = elem.textContent ?? "";
  const x = event.clientX - elem.getBoundingClientRect().left;

  let left = 0;
  let right = text.length;
  let res = -1;

  while (left < right) {
    const mid = Math.floor((left + right) / 2);
    const substring = text.substring(0, mid);
    const substringWidth = context.measureText(substring).width;

    if (substringWidth < x) {
      res = mid;
      left = mid + 1;
    } else if (substringWidth > x) {
      right = mid;
    } else {
      return mid;
    }
  }

  if (res >= 0) {
    const checkWidth = context.measureText(text.substring(0, res)).width;
    if (checkWidth > x && res > 0) {
      res -= 1;
    }
  }

  return res;
};

// Wrapper for async functions to run them in script setup or global context
export const run = (asyncFn: () => Promise<void>) => asyncFn();

export const gtag = (title: string, other: Record<string, string>) => {
  const { dataLayer } = window as unknown as { dataLayer: Record<string, string>[] };
  if (!dataLayer) {
    return;
  }
  dataLayer.push({ event: title, ...other });
};

export const gtagSignUp = (email: string, method: string) => {
  gtag("app_sign_up", { email, method });
};

export const isTimeTracking = (value: PropertyValue): value is TimeTracking =>
  Array.isArray(value) && value.length > 0 && typeof value[0] === "object";

export const getAncestorDuids = <T extends { duid: string; parentDuid?: string | null }>(items: T[], item: T) => {
  const itemsMap = new Map<string, T>(items.map((i) => [i.duid, i]));

  const result: string[] = [];
  const visited = new Set<string>();
  let curr: T = item;
  // limit linked list traversal to 1000 to avoid while(true) loop
  for (let i = 0; i < 1000; i += 1) {
    const currParentDuid = curr.parentDuid;
    if (!currParentDuid || visited.has(currParentDuid)) {
      break;
    }
    const next = itemsMap.get(currParentDuid);
    if (!next) {
      break;
    }
    result.push(currParentDuid);
    visited.add(currParentDuid);
    curr = next;
  }
  return result.reverse();
};

export const getDescendantDuids = <T extends { duid: string; parentDuid?: string | null }>(items: T[], item: T) => {
  const itemsMap = new Map<string | null | undefined, string[]>();

  items
    .filter((e) => e.parentDuid)
    .forEach(({ parentDuid, duid }) => {
      if (!itemsMap.has(parentDuid)) {
        itemsMap.set(parentDuid, []);
      }
      itemsMap.get(parentDuid)?.push(duid);
    });

  const result: string[] = [];
  const visited = new Set<string>();
  const queue: string[] = [item.duid];
  // limit BFS to 10000 to avoid while(true) loop
  for (let i = 0; i < 10000; i += 1) {
    if (queue.length === 0) {
      break;
    }
    const currDuid = queue.shift() as string;
    if (visited.has(currDuid)) {
      continue;
    }
    result.push(currDuid);
    visited.add(currDuid);
    const curr = itemsMap.get(currDuid);
    if (!curr) {
      continue;
    }
    queue.push(...curr);
  }
  return result.slice(1).flat();
};
