<script setup lang="ts">
import equal from "deep-equal";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropTarget from "~/components/dumb/DropTarget.vue";
import Modal from "~/components/dumb/Modal.vue";
import TcmActions from "~/components/task/tcm/TcmActions.vue";
import TcmBody from "~/components/task/tcm/TcmBody.vue";
import TcmFooterLeft from "~/components/task/tcm/TcmFooterLeft.vue";
import { TCM_TELEPORT_KEY } from "~/components/text/const";
import { TrashIcon } from "~/icons";
import { DropFilesIllustration } from "~/illustrations";
import {
  DialogMode,
  ModalPosition,
  ModalWidth,
  RelationshipKindKind,
  TaskSourceType,
  TutorialName,
} from "~/shared/enums";
import type { Dartboard, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const DISCARD_DRAFT_DIALOG_DESCRIPTION =
  "Discarding the draft will clear the task information you've put in so far. This can't be undone. Are you sure you want to proceed?";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const discardDraftModal = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const body = ref<InstanceType<typeof TcmBody> | null>(null);
const actions = ref<InstanceType<typeof TcmActions> | null>(null);
const footerLeft = ref<InstanceType<typeof TcmFooterLeft> | null>(null);

const titleEmpty = ref((dataStore.taskDraft?.title?.trim() ?? "") === "");
const draftIsEmpty = computed(
  () => (!dataStore.taskDraft || dataStore.isTaskEmpty(dataStore.taskDraft)) && titleEmpty.value
);
const draftCanBeFinished = computed(() => dataStore.taskDraft !== null && !titleEmpty.value);
let justCreatedAndUnedited = false;

const subtasks = computed(() => {
  if (!dataStore.taskDraft) {
    return [];
  }

  const childRelationships = dataStore.getRelationshipsByKindKind(
    dataStore.taskDraft as Task,
    RelationshipKindKind.PARENT_OF,
    true
  );
  return dataStore.getTasksByDuidsOrdered(
    childRelationships.map((e) => e.targetDuid),
    { includeDraft: true }
  );
});

const getOrderForNextTask = (dartboard: Dartboard) => {
  const topTaskOrder = dataStore.getTasksByDartboardDuidOrdered(dartboard.duid ?? "", {
    includeTrashed: true,
    includeDraft: true,
  })?.[0]?.order;
  return getOrdersBetween(undefined, topTaskOrder)[0];
};

const createDraft = (partialTask?: Partial<Task>) => {
  const dartboard = appStore.currentDartboardOrDefault;
  if (!dartboard) {
    return;
  }

  const order = getOrderForNextTask(dartboard);
  const initialTask = partialTask ?? {};
  initialTask.drafterDuid = userStore.duid;
  dataStore.createTask("", dartboard.duid, order, TaskSourceType.APP_TCM, initialTask, { noUndo: true });
};

const onTitleUpdate = (value: string) => {
  justCreatedAndUnedited = false;
  titleEmpty.value = value.trim() === "";
};

const onFieldUpdate = () => {
  justCreatedAndUnedited = false;
};

const resetModal = () => {
  justCreatedAndUnedited = false;
  body.value?.reset();
};

const closeModal = () => {
  appStore.setTcmOpen(false);
  nextTick(resetModal);
};

const finish = () => {
  resetModal();
  nextTick(() => body.value?.focusTitle());
};

const onEnter = async (event: KeyboardEvent) => {
  event.stopPropagation();
  if (!(pageStore.isMac ? event.metaKey : event.ctrlKey)) {
    return;
  }
  resetModal();
  await actions.value?.finish(event.shiftKey);
};

const discardDraftOrOpenModal = () => {
  if (!dataStore.taskDraft) {
    return;
  }

  if (draftIsEmpty.value || justCreatedAndUnedited) {
    closeModal();
    return;
  }

  discardDraftModal.value?.openModal();
};

const handleConfirmDiscardDraft = () => {
  dataStore.deleteTasks([dataStore.taskDraft as Task, ...subtasks.value.filter((e) => e.drafterDuid !== null)]);
  closeModal();
};

const generatePropertyRecommendations = () =>
  body.value?.generatePropertyRecommendations(footerLeft.value?.recommendationButton ?? null);

const generateSubtaskRecommendations = async () => {
  await body.value?.generateSubtaskRecommendations();
  userStore.updateTutorialStatuses([{ name: TutorialName.CREATE_TASK_WITH_SUBTASK_RECS, status: 4 }]);
};

const deselectSubtasks = () => body.value?.deselectSubtasks();

const createSubtask = async () => body.value?.createSubtask();

const openFilePicker = () => body.value?.openFilePicker();

const addAttachments = (files: File[]) => body.value?.addAttachments(files);

watch(
  [() => dataStore.taskDraft, () => dataStore.taskDraft?.description, () => dataStore.taskDraft?.title],
  ([newTaskDraft, newDescription], [oldTaskDraft, oldDescription]) => {
    // kinda a hack to make sure that this variable is set on desc changes; biggest problem is that it's not immediate
    if (
      newTaskDraft &&
      newTaskDraft.duid === oldTaskDraft?.duid &&
      !equal(newDescription, oldDescription, { strict: true })
    ) {
      justCreatedAndUnedited = false;
    }

    // If the title + description is not too short, check for duplicates
    const title = newTaskDraft?.title?.trim() ?? "";
    const description = body.value?.getDescription()?.trim() ?? "";
    if (title.length + description.length < 15) {
      return;
    }
    footerLeft.value?.updateDuplicateDetectionHub();
  }
);

watch(
  () => appStore.tcmOpen,
  (newValue) => {
    if (newValue) {
      if (!dataStore.taskDraft) {
        createDraft();
      } else {
        body.value?.reset();
      }
    } else if (dataStore.taskDraft && (dataStore.isTaskEmpty(dataStore.taskDraft as Task) || justCreatedAndUnedited)) {
      dataStore.deleteTasks([dataStore.taskDraft as Task]);
    }
  }
);

watch(
  () => dataStore.taskDraft,
  (newTaskDraft, oldTaskDraft) => {
    if (newTaskDraft === null) {
      closeModal();
      return;
    }
    if (newTaskDraft?.duid !== oldTaskDraft?.duid) {
      titleEmpty.value = (newTaskDraft?.title?.trim() ?? "") === "";
    }
  }
);

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.tcm = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.tcm = null;
});

defineExpose({
  createSubtask,
  generatePropertyRecommendations,
  generateSubtaskRecommendations,
  openFilePicker,
});
</script>

<template>
  <Modal
    id="dart-task-creation-modal-wrapper"
    data-testid="task-creation-modal"
    :entity="appStore.tcmOpen ? dataStore.taskDraft : undefined"
    title="Create a task"
    :position="pageStore.isMobile ? ModalPosition.BOTTOM : undefined"
    :width="ModalWidth.L"
    overflow-clip
    hide-title
    close-text="Discard draft"
    custom-styles="!px-0 !pb-0 align-middle h-full sm:h-fit"
    @close="discardDraftOrOpenModal">
    <template #default="{ entity: taskDraft }">
      <ConfirmationDialog
        ref="discardDraftModal"
        :mode="DialogMode.DELETE"
        title="Discard draft"
        :description="DISCARD_DRAFT_DIALOG_DESCRIPTION"
        confirm-text="Discard"
        cancel-text="Keep"
        :icon="TrashIcon"
        @confirm="handleConfirmDiscardDraft"
        @cancel="closeModal" />

      <div :data-toolbar="TCM_TELEPORT_KEY" @click.stop="deselectSubtasks" @keydown.enter.stop="deselectSubtasks">
        <DropTarget styles="rounded-lg" class="flex flex-col" @drop="addAttachments">
          <template #main>
            <TcmBody
              ref="body"
              :task="taskDraft"
              @enter="onEnter"
              @update-title="onTitleUpdate"
              @update="onFieldUpdate" />
          </template>
          <template #dragOverlay>
            <div class="flex w-full flex-col items-center justify-evenly">
              <div class="flex flex-col items-center">
                <DropFilesIllustration />
                <span class="mt-6 text-center text-2xl text-md">Drop to attach</span>
              </div>
              <div />
            </div>
          </template>
        </DropTarget>
      </div>
      <hr class="mt-3 w-[672px] border-t border-md" />
    </template>

    <template #footerLeft="{ entity: taskDraft }">
      <TcmFooterLeft
        ref="footerLeft"
        :task="taskDraft"
        :title-empty="titleEmpty"
        :generating-recommendations="
          !!body?.generatingPropertyRecommendations || !!body?.generatingSubtaskRecommendations
        "
        @generate-property-recommendations="generatePropertyRecommendations"
        @generate-subtask-recommendations="generateSubtaskRecommendations"
        @improve-description="body?.improveDescription"
        @reset="resetModal" />
    </template>

    <!-- Actions -->
    <template #actions="{ entity: taskDraft }">
      <TcmActions
        ref="actions"
        :task="taskDraft"
        :subtasks="subtasks"
        :draft-can-be-finished="draftCanBeFinished"
        @finish="finish"
        @close="closeModal"
        @enter="onEnter" />
    </template>
  </Modal>
</template>
