<script setup lang="ts">
defineProps<{
  title: string;
  subtitle?: string;
}>();
</script>

<template>
  <div class="flex justify-between gap-4">
    <span class="flex flex-col">
      <span class="select-none text-sm font-normal text-md">{{ title }}</span>
      <span class="select-none text-xs text-vlt">{{ subtitle }}</span>
    </span>
    <slot />
  </div>
</template>
