<script setup lang="ts">
import { Menu } from "floating-vue";
import moment from "moment";
import { computed, nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import Avatar from "~/components/dumb/Avatar.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpdateTime from "~/components/dumb/UpdateTime.vue";
import CommentEditor from "~/components/text/CommentEditor.vue";
import { DART_AI_PSEUDO_USER } from "~/constants/user";
import { ReplyIcon, TrashIcon } from "~/icons";
import { getQueryParam } from "~/router/common";
import { DialogMode } from "~/shared/enums";
import type { Comment, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { isAgentEmail } from "~/utils/validation";

import AddReaction from "./AddReaction.vue";
import Line from "./Line.vue";
import OptionsMenu from "./OptionsMenu.vue";
import Reactions from "./Reactions.vue";

const BUTTON_STYLE = "flex items-center rounded p-0.5 text-lt hover:bg-lt focus-ring-std";

const props = defineProps<{
  task: Task;
  comment: Comment;
  showThreadLine: boolean;
  hideSignature: boolean;
  editing: boolean;
}>();

const emit = defineEmits<{
  startEdit: [];
  endEdit: [];
  reply: [];
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const wrapper = ref<HTMLDivElement | null>(null);
const confirmDeleteModal = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const editor = ref<InstanceType<typeof CommentEditor> | null>(null);

const author = computed(() =>
  props.comment.authoredByAi ? DART_AI_PSEUDO_USER : dataStore.getUserByDuid(props.comment.authorDuid)
);
const inThread = computed(() => !!props.comment.rootDuid);

const inQparams = computed(() => getQueryParam("c") === props.comment.duid);
const isHighlighted = ref(
  inQparams.value ||
    (moment().diff(props.comment.publishedAt, "second") < 10 &&
      (props.comment.authoredByAi || props.comment.authorDuid !== userStore.duid))
);
watch(inQparams, (newValue) => {
  if (!newValue) {
    return;
  }
  isHighlighted.value = true;
});

const flashCounter = ref(0);

const initDelete = () => {
  confirmDeleteModal.value?.openModal();
};

const confirmDelete = () => {
  dataStore.deleteComment(props.comment);
};

watch(
  () => props.editing,
  (newEditing) => {
    if (!newEditing) {
      return;
    }

    setTimeout(() => editor.value?.focus());
  }
);

// Focus comment when highlighted
watch(
  isHighlighted,
  (newValue) => {
    if (!newValue) {
      return;
    }

    setTimeout(() => {
      const flashInterval = setInterval(() => {
        flashCounter.value += 1;
        if (flashCounter.value >= 6) {
          clearInterval(flashInterval);
        }
      }, 100);
    }, 500);
    setTimeout(() => {
      flashCounter.value = 0;
      isHighlighted.value = false;
      if (!inQparams.value) {
        return;
      }

      const query = { ...router.currentRoute.value.query };
      delete query.c;
      router.replace({ query });
    }, 5000);

    if (!inQparams.value) {
      return;
    }

    nextTick(() => wrapper.value?.scrollIntoView(false));
  },
  { immediate: true }
);

const sections = computed(() =>
  actions.context.comment(props.comment, (eventKind: string) => {
    switch (eventKind) {
      case "startEdit": {
        emit("startEdit");
        break;
      }
      case "delete": {
        initDelete();
        break;
      }
      default: {
        throw new Error(`Unknown event kind: ${eventKind}`);
      }
    }
  })
);

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, sections.value);
};

const startEdit = () => {
  emit("startEdit");
  nextTick(() => wrapper.value?.scrollIntoView());
};

watch(
  () => props.comment.reactions.length,
  () => {
    nextTick(() => wrapper.value?.scrollIntoView());
  }
);
</script>

<template>
  <Menu no-auto-focus show-group="comment-options" placement="top-end" :distance="-6" :delay="0">
    <div
      ref="wrapper"
      class="relative flex pl-2.5 transition-colors"
      :class="{
        'pr-[9px] bg-lt': editing,
        'pr-2.5 hover:bg-lt': !editing,
        'duration-75': flashCounter !== 0,
        'duration-500': flashCounter === 0,
        'bg-primary-base/20': isHighlighted && flashCounter % 2 === 0,
      }"
      @contextmenu="onContextMenu">
      <Line
        v-if="showThreadLine && inThread"
        class="mr-2 w-6 shrink-0 self-stretch"
        :no-turn="hideSignature"
        more-after />
      <div
        class="relative flex w-full flex-1 overflow-hidden"
        :class="{
          'gap-[3px]': editing,
          'gap-2.5': !editing,
          'py-1.5': editing,
          'pt-1.5': !hideSignature && !editing,
        }">
        <div class="flex flex-col self-stretch" :class="editing && '-mb-1.5'">
          <Avatar
            v-if="!hideSignature"
            :is-ai="comment.authoredByAi"
            :is-agent="isAgentEmail(author?.email ?? '')"
            :abrev="author?.abrev ?? 'U'"
            circle
            :color-hex="author?.colorHex"
            :image-url="author?.imageUrl"
            img-border
            class="mb-1 icon-lg" />
          <Line v-if="showThreadLine && !inThread" class="w-6 flex-1 shrink-0" more-after no-turn />
          <div v-else class="w-6 shrink-0" />
        </div>
        <div class="flex min-w-0 flex-1 flex-col">
          <div v-if="!hideSignature" :class="editing && 'pl-[7px]'" class="flex gap-2.5 overflow-hidden">
            <div
              :title="author?.name ?? '(User)'"
              class="cursor-default select-none truncate text-sm font-semibold text-md">
              {{ author?.name ?? "(user)" }}
            </div>
            <UpdateTime v-if="comment.publishedAt" :at="comment.publishedAt" tooltip-top />
          </div>
          <CommentEditor
            ref="editor"
            :task="task"
            :comment="comment"
            :editing="editing"
            :editor-classes="editing ? 'bg-std' : ''"
            @end-edit="emit('endEdit')"
            @delete="initDelete" />
          <Reactions :comment="comment" />
        </div>
      </div>
    </div>
    <template #popper>
      <div class="flex items-center gap-0.5 rounded border px-0.5 bg-std border-md">
        <div class="cursor-default border-r pl-1 pr-1.5 border-md">
          <UpdateTime v-if="comment.publishedAt" :at="comment.publishedAt" tooltip-top />
        </div>
        <span v-if="comment.edited" class="cursor-default border-r pl-0.5 pr-1 text-xs/[22px] text-vlt border-md">
          (edited)
        </span>
        <Tooltip v-if="!inThread" text="Reply">
          <button type="button" :class="BUTTON_STYLE" aria-label="Reply" @click="emit('reply')">
            <ReplyIcon class="icon-sm" />
          </button>
        </Tooltip>
        <AddReaction :comment="comment" is-contrast />
        <OptionsMenu :comment="comment" @start-edit="startEdit" @delete="initDelete" />
      </div>
    </template>
  </Menu>
  <ConfirmationDialog
    ref="confirmDeleteModal"
    :mode="DialogMode.DELETE"
    title="Delete comment"
    description="Permanently deleting a comment can't be undone. Are you sure you want to proceed?"
    confirm-text="Delete"
    cancel-text="Keep"
    :icon="TrashIcon"
    @confirm="confirmDelete" />
</template>
