<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import { EditorMode, NumberFormat } from "~/shared/enums";
import type { PropertyNumber } from "~/shared/types";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  property: PropertyNumber;
  editorMode: EditorMode;
  value?: number | null;
}>();

const emit = defineEmits<{
  update: [value: number | null];
}>();

const numberInput = ref<HTMLSpanElement | null>(null);
const isDollars = computed(() => props.property.adtl.format === NumberFormat.DOLLARS);
const isPercentage = computed(() => props.property.adtl.format === NumberFormat.PERCENTAGE);

const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isPropertyDefaultMode = computed(() => props.editorMode === EditorMode.PROPERTY_DEFAULT);

const updateText = (e: Event) => {
  const { value } = e.target as HTMLInputElement;
  emit("update", value === "" ? null : parseInt(value, 10));
};

const increment = () => {
  emit("update", (props.value ?? 0) + 1);
};

const decrement = () => {
  emit("update", (props.value ?? 0) - 1);
};

const onEnterPress = () => numberInput.value?.blur();

const id = ref(`number-editor-input-${makeUuid()}`);

onMounted(() => {
  if (isPropertyDefaultMode.value) {
    setTimeout(() => {
      numberInput.value?.focus();
    }, 0);
  }
});
</script>

<template>
  <div class="group/number-input relative w-full">
    <span v-if="isDollars && value !== null" class="pl-2">$</span>
    <label :for="id" class="sr-only">Value</label>
    <input
      :id="id"
      ref="numberInput"
      type="number"
      placeholder="Add number"
      class="w-full cursor-text hyphens-auto rounded border py-[5px] pr-2 text-sm caret-gray-700 bg-std text-md focus-ring-none break-words placeholder:text-vlt focus:border-primary-base dark:caret-zinc-300 dark:focus-ring-none dark:focus:border-primary-base"
      :class="{
        'border-0': isPropertyDefaultMode,
        'border-md hover:border-hvy': isFormMode,
        'pl-1': isDollars,
        'pl-2': !isDollars,
      }"
      :value="value"
      @input="updateText"
      @blur="updateText"
      @keydown.esc.stop.prevent="numberInput?.blur()"
      @keydown.enter.stop.prevent="onEnterPress"
      @paste="updateText" />
    <div
      class="absolute inset-y-0 right-1.5 flex select-none items-center gap-1.5 opacity-0 group-focus-within/number-input:opacity-100 group-hover/number-input:opacity-100">
      <span v-if="isPercentage && value !== null" class="text-xs text-vlt">%</span>
      <div class="flex-col justify-center">
        <div
          class="cursor-pointer rounded"
          :class="{
            'hover:bg-md': isFormMode,
            'text-vlt hover:bg-lt': isPropertyDefaultMode,
          }"
          @click="increment"
          @keydown.enter="increment">
          <ChevronUpIcon class="size-3" />
        </div>
        <div
          class="cursor-pointer rounded"
          :class="{
            'hover:bg-md': isFormMode,
            'text-vlt hover:bg-lt': isPropertyDefaultMode,
          }"
          @click="decrement"
          @keydown.enter="decrement">
          <ChevronDownIcon class="size-3" />
        </div>
      </div>
    </div>
  </div>
</template>
