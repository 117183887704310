<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import ReminderChip from "~/components/dumb/ReminderChip.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { ORDERED_RELATIONSHIP_CONFIGURATION } from "~/constants/relationship";
import {
  AttachmentFieldIcon,
  ChildRelationshipIcon,
  CommentIcon,
  DescriptionFieldIcon,
  DocIcon,
  LinkFieldIcon,
  NotionIcon,
  ParentRelationshipIcon,
  RelationshipsIcon,
} from "~/icons";
import { CommandId, EditorMode, Placement, RelationshipKindKind, SubtaskDisplayMode, ViewKind } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";
import { asyncState } from "~/utils/async";
import { isLexicalStateEmpty } from "~/utils/common";

const props = defineProps<{
  task: TaskAbsenteeMaybe;
  editorMode: EditorMode;
  hasShownParent: boolean;
  subtasksExpanded: boolean;
  hideSubtasks?: boolean;
}>();

const emit = defineEmits<{
  openTaskInDetail: [event: KeyboardEvent | MouseEvent];
  jumpToDescription: [event: KeyboardEvent | MouseEvent];
  jumpToComments: [event: KeyboardEvent | MouseEvent];
  toggleSubtasks: [event: KeyboardEvent | MouseEvent];
  openReminder: [event: KeyboardEvent | MouseEvent];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isListMiniOrMiniTcmMode = computed(
  () => props.editorMode === EditorMode.LIST_MINI || props.editorMode === EditorMode.LIST_MINI_TCM
);
const isListMiniMode = computed(
  () => isListMiniOrMiniTcmMode.value || props.editorMode === EditorMode.LIST_MINI_ROADMAP
);
const hasDescription = computed(() => !isLexicalStateEmpty(props.task.description));
const hasNotionDocument = computed(() => tenantStore.notionIntegration?.enabled && !!props.task.notionDocument);
const hasComments = computed(() => dataStore.getCommentsByTaskDuid(props.task.duid).filter((c) => !c.isDraft).length);
const hasAttachments = computed(() => props.task.attachmentDuids.length > 0);
const hasLinks = computed(() => props.task.links.length);

const parentTask = computed(() => {
  const parentDuid = dataStore.getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, false, {
    includeTrashed: isTrash.value,
  })[0]?.targetDuid;
  return dataStore.getTaskByDuid(parentDuid);
});

const parentText = computed(() => `${parentTask.value?.title}`);

const subtasks = computed(() =>
  dataStore.getTasksByDuidsOrdered(
    dataStore
      .getRelationshipsByKindKind(props.task, RelationshipKindKind.PARENT_OF, true, { includeTrashed: isTrash.value })
      .map((e) => e.targetDuid),
    { includeTrashed: isTrash.value }
  )
);
const completedStatusDuids = computed(
  () =>
    new Set(
      dataStore.getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty).map((e) => e.duid)
    )
);
const numSubtasksCompleted = computed(
  () => subtasks.value.filter((task) => completedStatusDuids.value.has(task.statusDuid)).length
);

const otherRelationships = computed(() => {
  const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
  return dataStore
    .getRelationships(props.task, { includeTrashed: isTrash.value })
    .filter((e) => e.kindDuid !== parentKindDuid);
});
const otherRelatedTasks = computed(() =>
  dataStore.getTasksByDuidsOrdered(
    otherRelationships.value.map((e) => e.targetDuid),
    { includeTrashed: isTrash.value }
  )
);
const otherRelatedTasksSections = computed(() =>
  ORDERED_RELATIONSHIP_CONFIGURATION.map(({ kind, isForward, label }) => {
    const relationshipDuid = dataStore.getRelationshipKindByKind(kind).duid;
    const matchingRelationships = otherRelationships.value.filter(
      (e) => e.kindDuid === relationshipDuid && (isForward === undefined || e.isForward === isForward)
    );
    if (matchingRelationships.length === 0) {
      return null;
    }

    const tasks = dataStore
      .getTasksByDuidsOrdered(
        matchingRelationships.map((e) => e.targetDuid),
        { includeTrashed: isTrash.value }
      )
      .map((task) => `${task.title}`);

    return {
      title: label,
      items: tasks,
    };
  }).filter((section) => section !== null)
);

const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);
const showParent = computed(() => !notFlat.value || !props.hasShownParent);

const docRelationships = computed(() => dataStore.getDocDuidsRelatedToTask(props.task.duid));
const { state: relatedDocs, isLoading: isLoadingRelatedDocs } = asyncState(
  () => dataStore.getDocsRelatedToTaskOrdered(props.task.duid),
  { default: [] }
);
const relatedDocsText = computed(() => [
  "Linked docs",
  ...(isLoadingRelatedDocs
    ? docRelationships.value.map((e) => dataStore.getDocByDuidIfLoaded(e)?.title ?? "Loading...")
    : relatedDocs.value.map((e) => e.title)
  ).map((e) => `• ${e}`),
]);

const openTaskInDetail = (event: KeyboardEvent | MouseEvent) => emit("openTaskInDetail", event);
const jumpToDescription = (event: KeyboardEvent | MouseEvent) => emit("jumpToDescription", event);
const jumpToComments = (event: KeyboardEvent | MouseEvent) => emit("jumpToComments", event);
const toggleSubtasks = (event: KeyboardEvent | MouseEvent) => emit("toggleSubtasks", event);
</script>

<template>
  <template v-if="!isListMiniMode && !task.absentee">
    <Tooltip v-if="hasDescription || hasNotionDocument" :command-id="CommandId.EDIT_DESCRIPTION">
      <button
        type="button"
        class="flex items-center justify-center rounded border text-gray-900/30 border-oncolor focus-ring-none icon-md hover:bg-opposite/10 dark:text-white/20"
        tabindex="-1"
        @click="jumpToDescription"
        @keydown.enter="jumpToDescription">
        <span class="sr-only">Open description</span>
        <component :is="hasNotionDocument ? NotionIcon : DescriptionFieldIcon" class="icon-xs" />
      </button>
    </Tooltip>
    <Tooltip v-if="hasComments" :command-id="CommandId.VIEW_COMMENTS">
      <button
        type="button"
        class="flex items-center justify-center rounded border text-gray-900/30 border-oncolor focus-ring-none icon-md hover:bg-opposite/10 dark:text-white/20"
        tabindex="-1"
        @click="jumpToComments"
        @keydown.enter="jumpToComments">
        <span class="sr-only">View comments</span>
        <CommentIcon class="icon-xs" />
      </button>
    </Tooltip>
    <Tooltip v-if="hasAttachments" :command-id="CommandId.VIEW_ATTACHMENTS">
      <button
        type="button"
        class="flex items-center justify-center rounded border text-gray-900/30 border-oncolor focus-ring-none icon-md hover:bg-opposite/10 dark:text-white/20"
        tabindex="-1"
        @click="openTaskInDetail"
        @keydown.enter="openTaskInDetail">
        <span class="sr-only">View attachments</span>
        <AttachmentFieldIcon class="icon-xs" />
      </button>
    </Tooltip>
    <Tooltip v-if="hasLinks" :command-id="CommandId.VIEW_LINKS">
      <button
        type="button"
        class="flex items-center justify-center rounded border text-gray-900/30 border-oncolor focus-ring-none icon-md hover:bg-opposite/10 dark:text-white/20"
        tabindex="-1"
        @click="openTaskInDetail"
        @keydown.enter="openTaskInDetail">
        <span class="sr-only">View links</span>
        <LinkFieldIcon class="icon-xs" />
      </button>
    </Tooltip>
    <Tooltip v-if="task.remindAt" :command-id="CommandId.SET_REMINDER">
      <ReminderChip
        :remind-at="task.remindAt || null"
        @click-open="(event) => emit('openReminder', event)"
        @click-finished-indicator="openTaskInDetail" />
    </Tooltip>
  </template>
  <Tooltip v-if="!isListMiniOrMiniTcmMode && parentTask && showParent" :text="parentText" :placement="Placement.TOP">
    <Tooltip text="Open parent">
      <button
        type="button"
        class="flex items-center justify-center rounded border text-gray-900/30 border-oncolor focus-ring-none icon-md hover:bg-opposite/10 dark:text-white/20"
        tabindex="-1"
        @click.stop="actions.visualization.navigateToTask(parentTask.duid)"
        @keydown.enter.stop="actions.visualization.navigateToTask(parentTask.duid)">
        <span class="sr-only">View parent task</span>
        <ParentRelationshipIcon class="icon-xs" />
      </button>
    </Tooltip>
  </Tooltip>
  <Tooltip
    v-if="!hideSubtasks && subtasks.length > 0"
    :command-id="
      notFlat && !isListMiniOrMiniTcmMode
        ? subtasksExpanded
          ? CommandId.HIDE_SUBTASKS
          : CommandId.SHOW_SUBTASKS
        : undefined
    "
    :text="isListMiniOrMiniTcmMode ? 'Subtasks' : !notFlat ? 'Open task to see subtasks' : undefined">
    <button
      type="button"
      class="flex h-5 items-center justify-center gap-0.5 rounded border px-0.5 text-gray-900/30 border-oncolor focus-ring-none hover:bg-opposite/10 dark:text-white/20"
      tabindex="-1"
      @click="toggleSubtasks"
      @keydown.enter="toggleSubtasks">
      <span class="sr-only">Toggle subtasks</span>
      <ChildRelationshipIcon class="icon-xs" />
      <span class="text-xs">{{ `${numSubtasksCompleted}/${subtasks.length}` }}</span>
    </button>
  </Tooltip>
  <template v-if="!isListMiniMode && !task.absentee">
    <Tooltip v-if="otherRelatedTasks.length > 0" :sections="otherRelatedTasksSections" :placement="Placement.TOP">
      <Tooltip text="Open task to see relationships">
        <button
          type="button"
          class="flex h-5 items-center justify-center gap-0.5 rounded border px-0.5 text-gray-900/30 border-oncolor focus-ring-none hover:bg-opposite/10 dark:text-white/20"
          tabindex="-1"
          @click="openTaskInDetail"
          @keydown.enter="openTaskInDetail">
          <span class="sr-only">View other relationships</span>
          <RelationshipsIcon class="icon-xs" />
          <span class="text-xs">{{ otherRelatedTasks.length }}</span>
        </button>
      </Tooltip>
    </Tooltip>
    <Tooltip
      v-if="(isLoadingRelatedDocs && docRelationships.length > 0) || relatedDocs.length > 0"
      :text="relatedDocsText">
      <button
        type="button"
        class="flex h-5 items-center justify-center gap-0.5 rounded border px-0.5 text-gray-900/30 border-oncolor focus-ring-none hover:bg-opposite/10 dark:text-white/20"
        tabindex="-1"
        @click="openTaskInDetail"
        @keydown.enter="openTaskInDetail">
        <span class="sr-only">View related docs</span>
        <DocIcon class="icon-xs" />
        <span class="text-xs">
          {{ isLoadingRelatedDocs ? docRelationships.length : relatedDocs.length }}
        </span>
      </button>
    </Tooltip>
  </template>
</template>
