<script setup lang="ts">
import { Tooltip } from "floating-vue";
import { inject } from "vue";

import TaskCard from "~/components/dumb/TaskCard.vue";
import { DOC_PREVIEW } from "~/constants/injection";
import { Placement } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";
import { asyncState } from "~/utils/async";

const props = defineProps<{
  task?: Task;
  docDuid?: string;
  block?: boolean;
  heightBlock?: boolean;
  distance?: number;
}>();

const pageStore = usePageStore();
const dataStore = useDataStore();

const { state: doc } = asyncState(() => dataStore.getDocByDuid(props.docDuid ?? ""), {
  default: undefined,
});

const DocPreview = inject(DOC_PREVIEW);
</script>

<template>
  <Tooltip
    no-auto-focus
    :disabled="pageStore.isMobile"
    :placement="Placement.LEFT"
    :skidding="4"
    :distance="distance"
    :auto-hide="task ? task.title !== '' : doc ? doc.title !== '' : false"
    :theme="`tooltip-arrowless-${pageStore.theme}`"
    class="flex"
    :class="{
      'max-w-full grow': block,
      'h-full': heightBlock,
    }">
    <slot />

    <template #popper>
      <div class="w-64 rounded border bg-std border-md" :class="task && 'p-2'">
        <TaskCard v-if="task" :task="task" include-dartboard />
        <DocPreview v-if="docDuid" :duid="docDuid" hide-remove borderless />
      </div>
    </template>
  </Tooltip>
</template>

<style>
.v-popper--theme-tooltip-arrowless-light .v-popper__arrow-container,
.v-popper--theme-tooltip-arrowless-dark .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-tooltip-arrowless-light .v-popper__wrapper,
.v-popper--theme-tooltip-arrowless-dark .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-tooltip-arrowless-light .v-popper__inner,
.v-popper--theme-tooltip-arrowless-dark .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
