<script setup lang="ts">
import { LoadingIcon } from "~/icons";

defineProps<{
  absolute?: boolean;
  small?: boolean;
}>();
</script>

<template>
  <div class="pointer-events-none flex w-full items-center justify-center" :class="[absolute && 'absolute inset-0']">
    <LoadingIcon class="size-8 animate-spin text-vlt" />
  </div>
</template>
