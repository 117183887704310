import { type Ref, watch } from "vue";

const MAX_TIMEOUT = 2 ** 31 - 1;

// TODO remove all uses of this
export const timeout = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const isTruthy = async (condition: Ref, timeoutMs: number | undefined = 5000) =>
  new Promise<boolean>((resolve) => {
    const start = Date.now();
    if (condition.value) {
      resolve(true);
      return;
    }

    const { stop } = watch(condition, (value) => {
      if (value) {
        stop();
        resolve(true);
      }
      if (Date.now() - start > timeoutMs) {
        stop();
        resolve(false);
      }
    });

    setTimeout(() => {
      stop();
      resolve(false);
    }, timeoutMs);
  });

export const isTruthyFn = async (condition: () => boolean, timeoutMs: number | undefined = 5000) =>
  new Promise<boolean>((resolve) => {
    const start = Date.now();

    const interval = setInterval(() => {
      if (condition()) {
        clearInterval(interval);
        resolve(true);
        return;
      }
      if (Date.now() - start > timeoutMs) {
        clearInterval(interval);
        resolve(false);
      }
    }, 100);
  });

// These functions should replace setTimeout in all cases
// This is a traditional setTimeout. It should only be used for use cases directly related to datetimes.
export const runAtSpecificDatetime = (callback: () => void, datetime: Date) => {
  const targetTime = datetime.getTime();
  let delay = targetTime - new Date().getTime();
  if (delay <= 0) {
    callback();
    return;
  }
  if (delay <= MAX_TIMEOUT) {
    setTimeout(callback, delay);
    return;
  }

  const interval = setInterval(() => {
    const now = new Date().getTime();
    delay = targetTime - now;
    if (delay <= MAX_TIMEOUT) {
      clearInterval(interval);
      setTimeout(callback, delay);
    }
  }, MAX_TIMEOUT);
};

// This runs the callback when a ref is true. It is better than setTimeout because it will be more likely to run the callback at just the right time.
export const runWhen = (condition: Ref, callback: () => void, timeoutMs: number | undefined = 5000) => {
  const start = Date.now();
  if (condition.value) {
    callback();
    return;
  }

  const { stop } = watch(condition, (value) => {
    if (!value) {
      if (timeoutMs && Date.now() - start > timeoutMs) {
        stop();
      }
      return;
    }
    callback();
    stop();
  });
};

// This runs the callback when a function is true. It is better than setTimeout because it will be more likely to run the callback at just the right time.
export const runWhenFn = (condition: () => boolean, callback: () => void, timeoutMs: number | undefined = 5000) => {
  const start = Date.now();

  const interval = setInterval(() => {
    if (condition()) {
      clearInterval(interval);
      callback();
      return;
    }
    if (Date.now() - start > timeoutMs) {
      clearInterval(interval);
    }
  }, 100);
};

// TODO remove all uses of this
// This is a non-ideal use of setTimeout. It should be used when absolutely necessary to delay the current code a bit.
export const runAtEndOfEventQueue = (callback: () => void) => setTimeout(callback);
