<script setup lang="ts">
import actions from "~/actions";
import LoadingSpinner from "~/components/dumb/LoadingSpinner.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import DocEditor from "~/components/text/DocEditor.vue";
import { XIcon } from "~/icons";
import { IconSize } from "~/shared/enums";
import type { Doc } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { asyncState } from "~/utils/async";

const props = defineProps<{
  duid: string;
  hideRemove?: boolean;
  borderless?: boolean;
  enableContextMenu?: boolean;
}>();

const emit = defineEmits<{
  remove: [doc: Doc];
  click: [event: MouseEvent];
  keydown: [event: KeyboardEvent];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const { state: doc, isLoading } = asyncState(() => dataStore.getDocByDuid(props.duid), {
  default: undefined,
});

const onContextMenu = (event: MouseEvent) => {
  if (!doc.value || (tenantStore.isDart && !pageStore.adminHidden && event.altKey) || !props.enableContextMenu) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, actions.context.doc(doc.value));
};

const onRemove = () => {
  if (!doc.value) {
    return;
  }

  emit("remove", doc.value);
};
</script>

<template>
  <div
    class="group/doc-preview relative flex cursor-pointer rounded"
    :class="[!borderless && 'border border-md', isLoading ? 'h-[139px]' : 'flex-col']"
    @contextmenu="onContextMenu"
    @click="emit('click', $event)"
    @keydown="emit('keydown', $event)">
    <LoadingSpinner v-if="isLoading" />
    <template v-else-if="doc">
      <div class="mx-2 mt-2 h-24 overflow-hidden">
        <DocEditor :doc="doc" mini />
      </div>
      <div class="flex items-center gap-2 rounded-b border-t px-2 py-1.5 text-sm bg-lt border-md">
        <PageIcon :page="doc" :size="IconSize.S" />
        <span class="truncate text-md">
          {{ doc.title }}
        </span>
      </div>
      <div
        class="absolute inset-0 rounded opacity-0 bg-md/20 group-hover/doc-preview:flex group-hover/doc-preview:opacity-100">
        <div
          v-if="!hideRemove"
          class="absolute right-1 top-1 rounded text-md hover:bg-hvy/80"
          @click.stop="onRemove"
          @keydown.enter.stop="onRemove">
          <XIcon class="icon-sm" />
        </div>
      </div>
    </template>
  </div>
</template>
