<script setup lang="ts">
import { computed, ref } from "vue";

import actions from "~/actions";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import TaskDropdownItem from "~/components/dumb/TaskDropdownItem.vue";
import TaskOrDocPreviewTooltip from "~/components/dumb/TaskOrDocPreviewTooltip.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { EXCLUDE_CURRENT_TASK_REASON } from "~/constants/relationship";
import { ParentRelationshipIcon, XIcon } from "~/icons";
import { EditorMode, RelationshipKindKind, StatusKind, TaskSourceType, ViewKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { makeDuid } from "~/utils/common";
import { makeTaskComparatorWithDisabledAndCurrentDartboard } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";
import { isAgentEmail, validateIsNotEmpty } from "~/utils/validation";

const props = defineProps<{
  task: Task;
  relationshipKindKind: RelationshipKindKind;
  isForward?: boolean;
  singleselect?: boolean;
  excludeDuids?: Set<string>;
  excludeReason?: string;
  editorMode: EditorMode;
  onBeforeOpen?: () => void;
  onAfterClose?: () => void;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const completedStatusDuids = computed(
  () =>
    new Set(
      dataStore.getStatusesByKinds([...COMPLETED_STATUS_KINDS], dataStore.defaultStatusProperty).map((e) => e.duid)
    )
);

const menu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const isTrash = computed(() => appStore.currentPage?.kind === ViewKind.TRASH);
const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);

const relationshipKindDuid = computed(() => dataStore.getRelationshipKindByKind(props.relationshipKindKind).duid);
const relationships = computed(() =>
  dataStore.getRelationshipsByKindKind(props.task, props.relationshipKindKind, props.isForward, {
    includeTrashed: isTrash.value,
  })
);
const targetTasks = computed(() =>
  dataStore.getTasksByDuidsOrdered(
    relationships.value.map((e) => e.targetDuid),
    { includeTrashed: isTrash.value }
  )
);

const shouldShowAiWorking = (target: Task) =>
  !target.assigneeDuids.some((duid) => {
    const user = dataStore.getUserByDuid(duid);
    return user && isAgentEmail(user.email);
  }) || dataStore.getStatusByDuid(target.statusDuid)?.kind === StatusKind.STARTED;

const onSelect = (taskDuid: string) => {
  // TODO switch to changeParent
  const firstRelationship = relationships.value[0];
  if (props.singleselect && firstRelationship) {
    dataStore.deleteRelationship(props.task.duid, firstRelationship.duid);
  }

  let sourceDuid;
  let targetDuid;
  if (props.isForward) {
    sourceDuid = props.task.duid;
    targetDuid = taskDuid;
  } else {
    sourceDuid = taskDuid;
    targetDuid = props.task.duid;
  }

  dataStore.createRelationship(sourceDuid, targetDuid, relationshipKindDuid.value);
};

const onCreate = (title: string) => {
  const visualization = appStore.getActiveVisualization();

  const dartboard = appStore.currentDartboardOrDefault;
  if (!relationshipKindDuid.value || !dartboard) {
    return;
  }

  const tasks = visualization.getAll();
  const topTaskOrder = tasks.length === 0 ? undefined : tasks[0].order;
  const order = getOrdersBetween(undefined, topTaskOrder)[0];
  dataStore.createTask(title, dartboard.duid ?? "", order, TaskSourceType.APP_RELATIONSHIP, {
    relationships: [
      {
        duid: makeDuid(),
        kindDuid: relationshipKindDuid.value,
        targetDuid: props.task.duid,
        isForward: !props.isForward,
      },
    ],
  });
};

const open = () => {
  menu.value?.open?.();
};

const removeTask = (taskDuid: string) => {
  const relationship = relationships.value.find((r) => r.targetDuid === taskDuid);
  if (!relationship) {
    return;
  }

  dataStore.deleteRelationship(props.task.duid, relationship.duid);
};

const getTaskDisabledAndReason = (task: Task) => {
  if (task.duid === props.task.duid) {
    return { disabled: true, reason: EXCLUDE_CURRENT_TASK_REASON };
  }
  if (props.excludeDuids?.has(task.duid)) {
    return { disabled: true, reason: props.excludeReason };
  }
  return { disabled: false, reason: undefined };
};

const comparator = makeTaskComparatorWithDisabledAndCurrentDartboard(
  dataStore.getSpaceByDuid,
  dataStore.getDartboardByDuid,
  (task) => getTaskDisabledAndReason(task).disabled,
  appStore.currentDartboardOrDefault
);

const taskOptions = computed(() =>
  dataStore
    .getTaskList()
    .sort(comparator)
    .map((task) => {
      const { disabled, reason } = getTaskDisabledAndReason(task);
      return {
        value: task.duid,
        label: task.title,
        selected: targetTasks.value.some((e) => e.duid === task.duid),
        disabled,
        disabledReason: reason,
        component: TaskDropdownItem,
        componentArgs: {
          task,
          lineThrough: completedStatusDuids.value.has(task.statusDuid),
        },
      };
    })
);

const openTaskInDetail = (target: Task) => {
  const visualization = appStore.getActiveVisualization();

  if (!isTaskDetailMode.value) {
    return;
  }

  const { duid } = target;
  if (!visualization.getRow(duid)) {
    actions.visualization.navigateToTask(duid);
    return;
  }

  actions.visualization.selectRowByIdAndScroll(duid);
};

defineExpose({
  open,
});
</script>

<template>
  <div>
    <MultiselectDropdownMenu
      ref="menu"
      :container="isChipMode ? '#dart-task-creation-modal-wrapper' : undefined"
      :items="taskOptions"
      placeholder="Link a task..."
      :distance="isChipMode ? undefined : -10"
      :validate="validateIsNotEmpty"
      :block="isTaskDetailMode"
      cover
      :width-pixels="isChipMode ? 448 : undefined"
      :close-on-select="!!singleselect"
      :on-after-close="onAfterClose"
      @add="onSelect"
      @remove="removeTask"
      @create="onCreate">
      <Tooltip v-if="isChipMode" text="Change parent" class="w-full">
        <div
          class="flex min-h-[20px] cursor-pointer items-center justify-center gap-1 rounded border px-1 py-0.5 text-md border-oncolor hover:bg-opposite/10">
          <ParentRelationshipIcon
            class="shrink-0"
            :class="[
              isChipMode ? 'icon-sm' : 'icon-xs',
              isChipMode && targetTasks.length !== 0 && 'text-primary-base',
            ]" />
          <span class="select-none">Parent</span>
        </div>
      </Tooltip>
    </MultiselectDropdownMenu>
    <div
      v-if="!isChipMode && targetTasks.length > 0"
      class="mb-2 flex size-full min-h-[20px] cursor-default flex-col gap-2">
      <TaskOrDocPreviewTooltip v-for="target in targetTasks" :key="target.duid" :task="target" :distance="141" block>
        <div
          class="group/relationship relative flex cursor-pointer items-center gap-1 hyphens-auto text-left break-words"
          :class="{
            'min-h-[38px] w-full rounded border pl-3 text-sm text-md border-lt hover:bg-lt hover:border-md':
              !isChipMode,
            'pl-2': isChipMode,
          }"
          @click.stop="openTaskInDetail(target)"
          @keydown.enter.stop="openTaskInDetail(target)">
          <div
            class="select-none hyphens-auto break-words"
            :class="[!isChipMode && 'grow py-1.5 pr-2', completedStatusDuids.has(target.statusDuid) && 'line-through']">
            {{ target.title }}
          </div>

          <div v-if="!isChipMode" class="mr-1 flex shrink-0 items-center gap-2.5">
            <div v-if="tenantStore.assigneeEnabled" class="flex w-[60px] shrink-0 items-center justify-center">
              <AvatarGroup
                :ai="tenantStore.aiAssignmentEnabled && target.assignedToAi"
                :show-ai-working="shouldShowAiWorking(target)"
                :duids="target.assigneeDuids"
                :limit="3"
                :first-user-only="!tenantStore.multipleAssigneesEnabled"
                large
                :editor-mode="editorMode" />
            </div>
            <StatusIcon class="icon-md" :duid="target.statusDuid" />
          </div>
          <!-- dummy elements to help avoid overshooting the x -->
          <template v-if="!pageStore.isPublicView && !task.inTrash">
            <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/relationship:flex" />
            <div class="absolute -right-1.5 top-0 hidden h-3 w-1.5 group-hover/relationship:flex" />
            <button
              type="button"
              class="absolute -right-1.5 -top-1.5 hidden cursor-pointer items-center justify-center rounded-full border bg-lt border-md icon-xs group-hover/relationship:flex"
              aria-label="Remove relationship"
              @click.stop="removeTask(target.duid)"
              @keydown.enter.stop="removeTask(target.duid)">
              <Tooltip text="Remove relationship">
                <XIcon class="size-full text-lt focus:outline-none" />
              </Tooltip>
            </button>
          </template>
        </div>
      </TaskOrDocPreviewTooltip>
    </div>
  </div>
</template>
