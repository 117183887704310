<script setup lang="ts">
import { isAxiosError } from "axios";
import { computed, nextTick, ref } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import { GoogleIcon } from "~/icons";
import { finishLogin, getQueryParam } from "~/router/common";
import { AnimationDuration, ButtonSize, ButtonStyle, GoogleLoginAction } from "~/shared/enums";
import { useEnvironmentStore, usePageStore } from "~/stores";
import { getGoogleUrl, run } from "~/utils/common";
import { validateName, validatePassword } from "~/utils/validation";

import AuthBaseView from "./AuthBaseView.vue";

enum Step {
  INITIAL,
  NAME,
}

const router = useRouter();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();

const tokenDuid = ref(getQueryParam("t") ?? "");
const query = { ...router.currentRoute.value.query };
delete query.t;
router.replace({ query });

const nameInput = ref<InstanceType<typeof Input> | null>(null);
const passwordInput = ref<InstanceType<typeof Input> | null>(null);

const step = ref(Step.INITIAL);

const isValidToken = ref(true);
const errorMsg = ref<string>("Invalid invitation, ask for a new one");
const token = ref<{
  email: string;
  inviterName: string;
  tenantName: string;
  samlEnabled: boolean;
} | null>(null);
run(async () => {
  const { data } = await backendOld.auth.checkInvitationToken(tokenDuid.value);
  pageStore.pageLoaded = true;
  isValidToken.value = data.valid;
  if (isValidToken.value) {
    token.value = data;
  }
});

const submitting = ref(false);

const acceptInvitationButtonEnabled = computed(
  () => step.value !== Step.INITIAL && nameInput.value?.isValid && passwordInput.value?.isValid
);

const acceptInvitation = async () => {
  if (!acceptInvitationButtonEnabled.value || !nameInput.value || !passwordInput.value) {
    return;
  }

  submitting.value = true;

  try {
    const { data } = await backendOld.auth.acceptInvitation(
      tokenDuid.value,
      nameInput.value.value,
      passwordInput.value.value
    );
    finishLogin(data);
  } catch {
    isValidToken.value = false;
  }
};

const nextStep = () => {
  if (step.value === Step.INITIAL) {
    step.value = Step.NAME;
    nextTick(() => {
      nameInput.value?.focus();
    });
    return;
  }
  if (acceptInvitationButtonEnabled.value) {
    acceptInvitation();
  }
};

const loginWithSso = async () => {
  const email = token.value?.email;
  if (!email) {
    isValidToken.value = false;
    return;
  }

  submitting.value = true;

  try {
    const { data } = await backendOld.auth.getSamlSsoRedirect(email);
    window.location.href = data.redirectUrl;
  } catch (error) {
    if (isAxiosError<{ errors: string[] }>(error)) {
      submitting.value = false;
      isValidToken.value = false;
      const msg = error.response?.data.errors[0];
      if (msg) {
        errorMsg.value = msg;
      }
    }
  }
};

const googleUrl = computed(() =>
  getGoogleUrl(
    GoogleLoginAction.LOGIN,
    environmentStore.googleRedirectUri,
    pageStore.isDesktopApp,
    pageStore.isMobileApp,
    token.value?.email,
    undefined,
    getQueryParam("next")
  )
);

const displayContinueInBrowser = ref(false);

const toggleContinueInBrowser = () => {
  if (!pageStore.isDesktopApp && !pageStore.isMobileApp) {
    return;
  }

  displayContinueInBrowser.value = !displayContinueInBrowser.value;
};
</script>

<template>
  <AuthBaseView>
    <template #heading>
      {{ token ? `Accept ${token.inviterName}'s invitation to ${token.tenantName}` : "Accept invitation to Dart" }}
    </template>

    <template #default>
      <Animated v-if="!displayContinueInBrowser" :duration="AnimationDuration.LONG" class="flex w-full flex-col gap-4">
        <template v-if="isValidToken">
          <template v-if="step === Step.INITIAL">
            <a
              v-if="!environmentStore.isPreview && !environmentStore.hideForReviewer"
              :href="googleUrl"
              :target="pageStore.isDesktopApp ? '_blank' : undefined"
              :rel="pageStore.isDesktopApp ? 'noopener noreferrer' : undefined">
              <span class="sr-only">Continue with Google</span>
              <Button :btn-style="ButtonStyle.PRIMARY" text="Continue with Google" :icon="GoogleIcon" block />
            </a>

            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.LARGE"
              text="Continue with email"
              block
              @click="nextStep" />

            <Button
              v-if="token?.samlEnabled"
              :btn-style="ButtonStyle.SECONDARY"
              :size="ButtonSize.LARGE"
              text="Continue with SSO"
              :working="submitting"
              block
              class="mb-6"
              @click="loginWithSso" />
          </template>

          <form v-else class="flex flex-col gap-4" @submit.prevent>
            <div class="hidden">
              <!-- Hidden element to help password managers -->
              <Input :init-value="token?.email" label="Email" placeholder="Email" />
            </div>
            <Input
              ref="nameInput"
              required
              label="Full Name"
              placeholder="Enter your full name"
              :disabled="submitting"
              :validate="validateName"
              @enter="nextStep" />
            <Input
              ref="passwordInput"
              label="Password"
              input-type="password"
              required
              placeholder="Create a password"
              :disabled="submitting"
              :validate="validatePassword"
              @enter="nextStep" />
            <Button
              :btn-style="ButtonStyle.PRIMARY"
              :text="step === Step.NAME ? 'Continue' : 'Accept invitation'"
              :disabled="!acceptInvitationButtonEnabled"
              :working="submitting"
              block
              @click="nextStep" />
          </form>
        </template>
        <p v-else class="text-center text-2xl text-md">{{ errorMsg }}</p>
      </Animated>
      <p v-else class="text-center text-2xl text-md">Finish in your browser</p>
    </template>

    <template v-if="displayContinueInBrowser" #footer>
      <button type="button" class="underline" @click="toggleContinueInBrowser" @keydown.enter="toggleContinueInBrowser">
        ← Back
      </button>
    </template>
  </AuthBaseView>
</template>
