<script setup lang="ts">
defineProps<{
  title: string;
  noDivider?: boolean;
}>();
</script>

<template>
  <div class="mt-6 flex flex-col gap-y-6">
    <div class="text-base font-medium text-hvy">{{ title }}</div>
    <slot />
    <hr v-if="!noDivider" class="border-t border-lt" />
  </div>
</template>
