<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

import PageNotFound from "~/components/dumb/PageNotFound.vue";
import { goHomeNextTick } from "~/router/common";
import { useAppStore, useDataStore } from "~/stores";
import { getDocLink, getFolderLink } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";
import { isValidDuid } from "~/utils/validation";

const props = defineProps<{
  folderDuid: string;
  slugSep: string;
  slug: string;
  docDuid?: string;
}>();

const currentInstance = getCurrentInstance();
const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const isNotFound = ref(false);
const folder = computed(() => dataStore.getFolderByDuid(props.folderDuid));

watchEffect(() => {
  if (!folder.value) {
    if (!isValidDuid(props.folderDuid)) {
      goHomeNextTick();
      return;
    }
    isNotFound.value = true;
    return;
  }
  isNotFound.value = false;
  appStore.setCurrentPage(folder.value);
  if (!router.currentRoute.value.params.docDuid) {
    setTimeout(() => {
      if (folder.value) {
        router.replace({
          params: { slugSep: "-", slug: getFolderLink(folder.value, dataStore.getSpaceByDuid).params.slug },
          query: { ...router.currentRoute.value.query },
        });
      }
    });
  }
});

const docs = computed(() => dataStore.getDocsByFolderDuidOrdered(props.folderDuid));

const createDoc = async (open = true) => {
  if (!folder.value) {
    return null;
  }

  const topDocOrder = docs.value[0]?.order;
  const doc = await dataStore.createDoc("", folder.value.duid, getOrdersBetween(undefined, topDocOrder)[0]);

  if (open) {
    router.push(getDocLink(doc));
  }
  return doc.duid;
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.spaceOrFolder = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.spaceOrFolder = null;
});

defineExpose({
  createDoc,
});
</script>

<template>
  <main v-if="!isNotFound" class="relative flex grow overflow-hidden">
    <RouterView />
  </main>
  <PageNotFound v-else />
</template>
