<script setup lang="ts">
import { useTextareaAutosize } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";

import { EditorMode, PropertyKind } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  property: Property;
  value?: string;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  update: [value: string];
}>();

const textInner = ref(props.value ?? "");
const { textarea } = useTextareaAutosize({ input: textInner });

const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isPropertyDefaultMode = computed(() => props.editorMode === EditorMode.PROPERTY_DEFAULT);

watch(
  () => props.value,
  (text) => {
    const newText = text ?? "";
    if (newText === textInner.value) {
      return;
    }

    textInner.value = newText;
  }
);

const updateText = () => {
  emit("update", textInner.value);
};

const onEnterPress = () => textarea.value?.blur();

onMounted(() => {
  if (isPropertyDefaultMode.value) {
    setTimeout(() => {
      textarea.value?.focus();
    }, 0);
  }
});
</script>

<template>
  <textarea
    ref="textarea"
    v-model="textInner"
    :aria-label="property.title"
    :placeholder="property.kind === PropertyKind.DEFAULT_TITLE ? 'Write a task' : `Add text`"
    class="min-h-8 w-full cursor-text select-none resize-none hyphens-auto rounded border px-2 py-[5px] text-sm caret-gray-700 bg-std text-md focus-ring-none break-words placeholder:text-vlt focus:border-primary-base dark:caret-zinc-300 dark:focus-ring-none dark:focus:border-primary-base"
    :class="{
      'border-0': isPropertyDefaultMode,
      'border-md hover:border-hvy': isFormMode,
    }"
    @keydown.esc.stop.prevent="textarea?.blur()"
    @keydown.enter.stop.prevent="onEnterPress"
    @paste="updateText"
    @blur="updateText" />
</template>
