<script setup lang="ts">
import { computed, ref } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import LayoutEditorInner from "~/components/topbar/LayoutEditorInner.vue";
import { LAYOUT_KIND_TO_NAME_AND_ICON } from "~/constants/layout";
import { ChevronDownIcon } from "~/icons";
import { CommandId, DropdownMenuItemKind, PageKind, Placement, ViewKind } from "~/shared/enums";
import type { SpecificPageKind } from "~/shared/types";
import { useAppStore, usePageStore } from "~/stores";

const VIEW_KINDS_THAT_DISABLE_LAYOUT_SWITCH = new Set<SpecificPageKind>([ViewKind.SEARCH, ViewKind.TRASH]);

const appStore = useAppStore();
const pageStore = usePageStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const open = () => dropdownMenu.value?.open();

const canSwitchViews = computed(
  () =>
    appStore.currentPage &&
    appStore.currentPage.pageKind !== PageKind.FOLDER &&
    appStore.currentPage.pageKind !== PageKind.FORM &&
    !VIEW_KINDS_THAT_DISABLE_LAYOUT_SWITCH.has(appStore.currentPage?.kind)
);

const nameAndIcon = computed(() => LAYOUT_KIND_TO_NAME_AND_ICON.get(appStore.layoutKind));

const dropdownSections = computed(() => [
  {
    title: "Modify",
    items: [
      {
        title: "Layout",
        kind: DropdownMenuItemKind.COMPONENT,
        noFocus: true,
        component: LayoutEditorInner,
        componentArgs: {
          layoutKind: appStore.layoutKind,
          subtaskDisplayMode: appStore.subtaskDisplayMode,
          showAbsentees: appStore.showAbsentees,
          groupBy: appStore.groupBy,
          hideEmptyGroups: appStore.hideEmptyGroups,
          colorBy: appStore.colorBy,
          onSetLayoutKind: appStore.setLayoutKind,
          onSetSubtaskDisplayMode: appStore.setSubtaskDisplayMode,
          onSetShowAbsentees: appStore.setShowAbsentees,
          onSetHideEmptyGroups: appStore.setHideEmptyGroups,
          onSetPropertyDuidVisibility: appStore.setPropertyDuidVisibility,
          onSetGroupBy: appStore.setGroupBy,
          onSetColorBy: appStore.setColorBy,
        },
      },
    ],
  },
]);

defineExpose({
  open,
});
</script>

<template>
  <DropdownMenu
    v-if="canSwitchViews && nameAndIcon"
    ref="dropdownMenu"
    :sections="dropdownSections"
    :placement="Placement.BOTTOM_RIGHT"
    :distance="2"
    is-contrast
    :width-pixels="380"
    :max-height-pixels="960"
    dont-propagate-close>
    <Tooltip :command-ids="[CommandId.SET_LAYOUT_TO_NEXT, CommandId.SET_LAYOUT_TO_PREVIOUS]">
      <div
        data-testid="open-layout-editor"
        class="flex h-6 items-center rounded border pl-1 pr-0.5 border-hvy hover:bg-lt"
        :class="!pageStore.isMobile && 'gap-1'">
        <component :is="nameAndIcon[1]" class="mr-1 text-lt icon-xs" />
        <span v-if="!pageStore.isMobile" class="select-none text-sm text-md">{{ nameAndIcon[0] }}</span>
        <ChevronDownIcon class="text-lt icon-xs" />
      </div>
    </Tooltip>
  </DropdownMenu>
</template>
